import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.scss';

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div  className='arrowprev'  onClick={onClick}
        >

            <img  src='img_9.png'/>

        </div>
    );
};

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className='arrownext'  onClick={onClick}
   >

            <img  src='img_8.png'/>

        </div>
    );
};

const Carousel = () => {
    const settings = {

        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div>
                    <img className='noob' src="img_5.png" alt="Slide 1" />
                </div>
                <div>
                    <img className='noob' src="img_6.png" alt="Slide 2" />
                </div>
                <div>
                    <img className='noob' src="img_7.png" alt="Slide 3" />
                </div>
            </Slider>
        </div>
    );
}

export default Carousel;