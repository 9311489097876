import logo from './logo.svg';
import './App.scss';
import Carousel from "./Carousel";

function App() {
  return (
    <div className="App">





        < div >
        <div className='head1'>
        <h1>LOVE CONNECT</h1>
        </div>
        <div className='head2'>
        <div className='head2cont'>
        <p className='p1'>Ignite Connections with Targeted Email Marketing</p>
        <p className='p2'> Boost your dating site's engagement and conversions</p>
        <div className='button1'>Get a Free Consultation</div>
        </div>
        </div>


        <div className='secondPage'>
              <p className='textp1'>Why Choose <span>LoveConnect</span> Marketing?</p>
        <div className='Container'>
              <div className='text'>

        <p className='textp2'>At LoveConnect Marketing, we understand the unique challenges of the dating industry.
        Our team of expert email marketers combines data-driven
        strategies with a passion for creating meaningful connections.
        With years of experience and a track record of success,
        we help dating platforms:

        </p>
<div className='text2'>
      <p> Increase user engagement<br/>
            - Boost match rates<br/>
            - Improve conversion rates<br/>
            - Retain active users</p>
      <img src='img.png'/>
</div>

        </div>

        </div>
        </div>


        <div className='ThirdPage'>
        <div className='header11'>Our Tailored Email Marketing Services</div>
        <div className='Items'>
        <div className='Item'>
        <img src='img_1.png'/>
        <div className='ItemContainer'>
        <h1>Personalized Campaigns</h1>
        <p>Craft unique email journeys that speak directly to your users dating preferences and behaviors.</p>
        </div>

        </div>
        <div className='Item'>
        <img src='img_2.png'/>
        <div className='ItemContainer'>
        <h1>A/B Testing & Optimization</h1>
        <p>Continuously improve your email performance with data-driven insights and split testing.</p>
        </div>

        </div>
        <div className='Item'>
        <img src='img_3.png'/>
        <div className='ItemContainer'>
        <h1>Compliance & Deliverability</h1>
        <p>Ensure your emails land in inboxes, not spam folders, while adhering to all relevant regulations.</p>
        </div>

        </div>
        <div className='Item'>
        <img src='img_4.png'/>
        <div className='ItemContainer'>
        <h1>Automated Drip Campaigns</h1>
        <p>Nurture leads and re-engage dormant users with perfectly timed, automated email sequences.</p>
        </div>

        </div>
        </div>



        </div>
        <div className='Four'>
        <h1 className='head3'>Success Story: DatePerfect</h1>
        <p className='p3'>We helped DatePerfect, a niche dating site for professionals, achieve remarkable results</p>
        <Carousel/>
        <h1 className='head4'>Statistics</h1>
        <p className='p4'>150% increase in email open rates<br/>
        200% boost in click-through rates<br/>
        75% improvement in user activation</p>
        </div>
              <div className='Five'>

<div className='FiveContainer'>
<p className='p5'>"LoveConnect Marketing transformed our email strategy. Their expertise in the dating niche is unparalleled, and the results speak for themselves."</p>
<p className='p6'> - Sarah Johnson, CMO of DatePerfect</p>
<img src='img_10.png'/>
</div>

              </div>
              <div className='Six'>
<h1>Ready to Spark More Connections?</h1>
<p>Let's create an email strategy that turns matches into lasting relationships</p>
<div className='form'>
<input placeholder='Name'/>
      <input placeholder='Email'/>
      <input placeholder='Phone'/>
<div className='button2'>Claim free consultation</div>
</div>
<img className='abs' src='img_11.png'/>
              </div>
              <div className='Footer'>LoveConnect 2024</div>
        </div>






    </div>
  );
}

export default App;
